import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TUNE_IN_BADGE_LOGO } from '../../../../constants/localizations/shared';
import { HOME_BROWSE_PATH } from '../../../../constants/paths';
import { LocationAndLocalizationContext } from '../../../../providers/LocationAndLocalizationProvider';
import assetUrl from '../../../../utils/assetUrl';

const tiImageSrc = assetUrl('assets/img/ti-badge-full-white.svg');

export function NavHomeTuneInLink() {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <Link to={HOME_BROWSE_PATH}>
      <img
        src={tiImageSrc}
        width="50"
        height="20"
        role="presentation"
        alt={getLocalizedText(TUNE_IN_BADGE_LOGO)}
      />
    </Link>
  );
}

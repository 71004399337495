import url from 'url';
import compact from 'lodash/compact';
import { IN_APP_REDIRECT } from '../../constants/queryParams';
import { getGuideItemSubscribeSuccessRedirectLink } from '../../utils/guideItem/getGuideItemPathname';
import getGuideItemProducts from '../../utils/guideItem/getGuideItemProducts';
import createSubscribeLink from '../../utils/subscription/createSubscribeLink';

const createAndOpenSubscribeLink = (
  guideItem,
  location,
  products,
  source,
  history,
) => {
  const subscribeSuccessRedirectLink =
    getGuideItemSubscribeSuccessRedirectLink(guideItem);
  const additionalParams = {
    sku: compact(products || getGuideItemProducts(guideItem)),
    ...(subscribeSuccessRedirectLink && {
      [IN_APP_REDIRECT]: subscribeSuccessRedirectLink,
    }),
  };

  const subscribeLink = url.format(
    createSubscribeLink({
      source: source || location?.query?.source,
      guideId: guideItem?.guideId,
      location,
      additionalParams,
    }),
  );

  if (history) {
    return history.push(subscribeLink);
  }

  window.open(subscribeLink, '_self');
};

export default createAndOpenSubscribeLink;

import url from 'url';
import omit from 'lodash/omit';
import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AUTHORIZE_CONFIRM_PATH } from 'src/common/constants/paths';
import useOnMount from 'src/common/hooks/useOnMount';
import { selectAuth } from 'src/common/selectors/auth';
import { selectProducts } from 'src/common/selectors/products';
import { hasPairAuthFlowParam } from 'src/common/utils/auth/partnerAuthFlowHelpers';
import {
  SELECTED_SKU_QUERY_PARAM,
  SKU_QUERY_PARAM,
} from '../../constants/queryParams';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import getLoginRedirect from '../../utils/auth/getLoginRedirect';
import { selectProductFromQuery } from '../../utils/subscription/findProductHelpers';
import { BaseLoader } from '../Loader';
import PairingUpsellLoader from '../auth/pairing/PairingUpsellLoader';

const PremiumUpsellRoute = (props) => {
  const auth = useSelector(selectAuth);
  const products = useSelector(selectProducts);
  const { location } = useContext(LocationAndLocalizationContext);
  const history = useHistory();
  const { query } = location;

  const determineRoute = () => {
    const isPairingFlow = hasPairAuthFlowParam(query);
    const loginRedirect = getLoginRedirect(auth, location);

    // Send user to login, if not auth'd in
    if (loginRedirect) {
      return url.format(loginRedirect);
    }

    if (isPairingFlow) {
      const selectedProduct = selectProductFromQuery(products, location);

      // If user is in pairing flow, they will need a sku in order to show the expected product copy on the upsell,
      // if none are found, the app completes pairing without flowing through premium product upsells
      if (!selectedProduct?.sku) {
        return url.format({
          pathname: AUTHORIZE_CONFIRM_PATH,
          query: omit(query, [SKU_QUERY_PARAM, SELECTED_SKU_QUERY_PARAM]),
        });
      }
    }

    return null;
  };

  const routeTo = useRef(determineRoute());

  useOnMount(() => {
    if (routeTo.current) {
      history.replace(routeTo.current);
    }
  });

  return routeTo.current ? <BaseLoader /> : <PairingUpsellLoader {...props} />;
};

export default PremiumUpsellRoute;

import loadable from '@loadable/component';
import { Component } from 'react';
import createBundleLoader from '../shared/createBundleLoader';

const LoadableComponent = loadable(
  () => import(/* webpackChunkName: "landing" */ './Landing'),
  {
    fallback: createBundleLoader('Landing'),
  },
);

export default class LandingLoader extends Component {
  // used in TuneInLogo.js
  static preload() {
    if (LoadableComponent.preload) {
      LoadableComponent.preload();
    }
  }

  render() {
    return <LoadableComponent {...this.props} />;
  }
}

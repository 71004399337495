import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { MAP_VIEW_ENABLED } from '../../constants/experiments/mapView';
import { ROOT_PATH } from '../../constants/paths';
import { selectExperiment } from '../../selectors/config';
import { BaseLoader } from '../Loader';
import MapViewLoader from './MapViewLoader';

class MapViewRoute extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isMapViewEnabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    if (!props.isMapViewEnabled) {
      this.isRedirecting = true;
      props.history.replace(`${ROOT_PATH}${props.location.search}`);
    }
  }

  render() {
    const { isMapViewEnabled, ...restProps } = this.props;

    return this.isRedirecting ? (
      <BaseLoader />
    ) : (
      <MapViewLoader {...restProps} />
    );
  }
}

export function mapStateToProps(state) {
  return {
    isMapViewEnabled: selectExperiment(state, MAP_VIEW_ENABLED, false),
  };
}

export default connect(mapStateToProps)(MapViewRoute);

import cssVariables from '../../../styles/variables';

export const okDialogStyles = {
  content: {
    maxWidth: 320,
  },
  body: {
    padding: '20px 20px 28px',
  },
};

export const commonDialogStyles = {
  narrowContent: {
    maxWidth: 510,
    overflow: 'hidden',
  },
  wideContent: {
    overflow: 'hidden',
  },
  body: {
    padding: 30,
  },
  darkTheme: {
    body: {
      backgroundColor: cssVariables['--secondary-color-5'],
    },
  },
};

import classNames from 'clsx';
import find from 'lodash/find';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { LANDING_SPLASH_HEADER_ENABLED } from '../../constants/experiments/config';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import withScrollPosition from '../../decorators/withScrollPosition';
import { selectIsMobile, selectTopBannerHeight } from '../../selectors/app';
import { selectShouldShowDesktopUpdateBanner } from '../../selectors/desktop';
import { selectValidatedHeroCarouselItems } from '../../selectors/heroCarousel';
import icons from '../../styles/icons.module.scss';
import cssVariables from '../../styles/variables';
import SearchIcon from '../shared/svgIcons/SearchIcon';
import TuneInLogo from './TuneInLogo';
import css from './header.module.scss';

export const Header = ({
  showSidebarToggle,
  onSidebarToggle,
  isMinimalHeader,
  unlinkedLogo,
  isLanding,
  positionThree,
  positionOne,
  positionTwo,
  offset, // scrollOffset
  toggleScrollListener,
  isLeftSideOpen,
  shouldShowDesktopUpdateBanner,
  topBannerHeight,
  carouselElements,
  isDesktop,
  isMobile,
  [LANDING_SPLASH_HEADER_ENABLED]: landingSplashHeaderEnabled,
}) => {
  useEffect(() => {
    toggleScrollListener?.(!isLeftSideOpen);
  }, [toggleScrollListener, isLeftSideOpen]);

  const areColorsInverted =
    (!!landingSplashHeaderEnabled && isLanding) ||
    isMinimalHeader ||
    !!find(carouselElements, (o) => o.bgColor === cssVariables['--black']);

  let logoColor = areColorsInverted
    ? 'white'
    : cssVariables['--secondary-color-5'];
  if (isLanding && positionTwo) {
    logoColor = cssVariables['--t-sharp'];
  }

  if (isMinimalHeader) {
    return (
      <div
        className={classNames(css.header, css.minimalHeader)}
        data-testid={positionThree ? 'scrolledHeader' : 'header'}
      >
        <div className={css.tuneInLogoContainer} data-testid="minimal-header">
          <TuneInLogo
            unlinkedLogo={unlinkedLogo}
            iconProps={{ fill: logoColor }}
            isDesktop={isDesktop}
          />
        </div>
      </div>
    );
  }

  const topBannerOffset = shouldShowDesktopUpdateBanner
    ? { top: topBannerHeight }
    : {};

  const scrollTopOffset = {
    offset: isLanding ? 0 : offset * -1,
  };

  const headerStyling = {
    ...scrollTopOffset,
    ...topBannerOffset,
  };

  let headerIconColor = cssVariables['--secondary-color-5'];
  if (areColorsInverted || (isLanding && positionTwo)) {
    headerIconColor = 'white';
  }

  return (
    <div
      className={classNames(css.header, {
        [css.isLanding]: isLanding,
        [css.positionOne]: isLanding && positionOne,
        [css.positionTwo]: isLanding && positionTwo,
        [css.positionThree]: isLanding && positionThree,
        [css.colorsInverted]: areColorsInverted,
        [css.mobile]: isMobile,
      })}
      data-testid={positionThree ? 'scrolledHeader' : 'header'}
      style={headerStyling}
    >
      <div className={css.headerWrapper}>
        {showSidebarToggle && (
          <div
            data-testid="sidebarToggleWrapper"
            className={css.sidebarToggleIconContainer}
            onClick={onSidebarToggle}
          >
            <i id="sidebarToggle" className={icons['icon-menu']} />
          </div>
        )}
        <div className={css.tuneInLogoContainer}>
          <TuneInLogo iconProps={{ fill: logoColor }} />
        </div>
        <Link
          to="/search/"
          data-testid="searchOpen"
          className={css.searchToggleContainer}
        >
          <SearchIcon fill={headerIconColor} width="30px" height="30px" />
        </Link>
      </div>
    </div>
  );
};

Header.propTypes = {
  positionThree: PropTypes.bool,
  positionOne: PropTypes.bool,
  positionTwo: PropTypes.bool,
  offset: PropTypes.number,
  toggleScrollListener: PropTypes.func,
  onSidebarToggle(props, propName, componentName) {
    if (
      props.showSidebarToggle === true &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error(
        `Please provide a ${propName} function to ${componentName}!`,
      );
    }
  },
  showSidebarToggle: PropTypes.bool,
  isMinimalHeader: PropTypes.bool,
  unlinkedLogo: PropTypes.bool,
  isLanding: PropTypes.bool,
  sourceGuideId: PropTypes.string,
  shouldShowDesktopUpdateBanner: PropTypes.bool,
  topBannerHeight: PropTypes.number,
  carouselElements: PropTypes.array,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isLeftSideOpen: PropTypes.bool,
  [LANDING_SPLASH_HEADER_ENABLED]: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    shouldShowDesktopUpdateBanner: selectShouldShowDesktopUpdateBanner(state),
    topBannerHeight: selectTopBannerHeight(state),
    carouselElements: selectValidatedHeroCarouselItems(state),
    isMobile: selectIsMobile(state),
  };
}

export default flow(
  connectWithExperiments([LANDING_SPLASH_HEADER_ENABLED]),
  withScrollPosition({ verticalBreakpoint1: 100, verticalBreakpoint3: 460 }),
  connect(mapStateToProps),
)(Header);

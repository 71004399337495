import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import cssVariables from '../../../styles/variables';
import { lightnessModifier, opacityModifier } from '../../../utils/color';
import css from './pill-button.module.scss';

class PillButton extends Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node.isRequired,
    labelClassName: PropTypes.string,
    dataTestId: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    tabIndex: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    icon: PropTypes.node,
    isSmall: PropTypes.bool,
    hasRightSideIcon: PropTypes.bool,
    tooltipId: PropTypes.string,
    tooltipText: PropTypes.string,
    backgroundColor: PropTypes.string,
    hoverColor: PropTypes.string,
    disabledColor: PropTypes.string,
    textColor: PropTypes.string,
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    labelClassName: '',
  };

  state = {
    isHovering: false,
    fallbackHoverColor: lightnessModifier(
      this.props.backgroundColor,
      cssVariables['--lighter-hover-luminosity-value'],
    ),
    fallbackDisabledColor: opacityModifier(
      this.props.backgroundColor,
      cssVariables['--lighter-disabled-opacity-value'],
    ),
  };

  handleMouseLeave = (event) => {
    const { isDisabled, onMouseLeave } = this.props;

    if (!isDisabled) {
      if (onMouseLeave) onMouseLeave();
      this.setState({ isHovering: false });
      event.target.blur();
    }
  };

  handleMouseEnter = () => {
    const { isDisabled, onMouseEnter } = this.props;

    if (!isDisabled) {
      if (onMouseEnter) onMouseEnter();
      this.setState({ isHovering: true });
    }
  };

  render() {
    const {
      id,
      dataTestId,
      className,
      isDisabled,
      type,
      tabIndex,
      onClick,
      label,
      labelClassName,
      icon,
      isSmall,
      hasRightSideIcon,
      tooltipId,
      tooltipText,
      backgroundColor,
      hoverColor,
      disabledColor,
      textColor,
      autoFocus,
    } = this.props;
    const { isHovering, fallbackHoverColor, fallbackDisabledColor } =
      this.state;

    let leftSideIcon;
    let rightSideIcon;

    if (hasRightSideIcon) {
      rightSideIcon = icon;
    } else {
      leftSideIcon = icon;
    }

    const style = {
      button: {},
      span: {},
    };

    if (backgroundColor && isDisabled) {
      style.button.backgroundColor = disabledColor || fallbackDisabledColor;
    }

    if (backgroundColor && !isDisabled) {
      const hoverBgColor = hoverColor || fallbackHoverColor;
      style.button.backgroundColor = isHovering
        ? hoverBgColor
        : backgroundColor;
    }

    return (
      <button
        id={id}
        data-testid={dataTestId}
        className={classNames(css.solidButton, className)}
        disabled={isDisabled}
        type={type || 'button'}
        tabIndex={tabIndex || '0'}
        data-small={isSmall}
        onClick={(...args) => !isDisabled && onClick && onClick(...args)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        data-for={tooltipId}
        data-tip={tooltipText}
        style={style.button}
        autoFocus={autoFocus}
      >
        <span style={{ color: textColor }} className={labelClassName}>
          {leftSideIcon}
          {label}
          {rightSideIcon}
        </span>
      </button>
    );
  }
}

export default PillButton;

import { isProdEnv } from '../utils/environment';
import config from './index';

export function getBaseApiConfig(isDiscord, isFord, isAuto) {
  let baseApiConfig = config.api;

  if (isDiscord) {
    baseApiConfig = config.discordApi;
  }

  // allow Ford to override Discord API config, as `isDiscord` is temporarily set to true for Ford to enable Discord UI
  if (isFord) {
    baseApiConfig = config.fordApi;
  }

  // `isFord` is temporarily set to true for Auto mode until we can update isFord references to isAuto throughout the app
  if (isAuto) {
    baseApiConfig = config.autoApi;
  }

  return {
    ...baseApiConfig,
    domains: {
      ...baseApiConfig.domains,
      local:
        isDiscord && !isFord && isProdEnv()
          ? baseApiConfig.domains.discordProductionLocal // in prod, discord uses `https://discord.tunein.com/api/v1`
          : baseApiConfig.domains.local,
    },
  };
}

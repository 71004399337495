import url from 'url';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectIsSubscriptionEnabled } from 'src/common/selectors/config';
import { ACCOUNT_PATH, PAYMENT_PATH } from '../../constants/paths';
import getLoginRedirect from '../../utils/auth/getLoginRedirect';
import parse from '../../utils/queryString/parse';
import shouldUseStripe from '../../utils/subscription/shouldUseStripe';
import { BaseLoader } from '../Loader';
import AccountSettingsLoader from './AccountSettingsLoader';

class AccountSettingsRoute extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    isStripeEnabled: PropTypes.bool.isRequired,
    isSubscriptionEnabled: PropTypes.bool.isRequired,
    routeProps: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const redirectRoute = this.determineRoute();

    if (redirectRoute) {
      this.props.history.replace(redirectRoute);
    }
    this.state = { redirectRoute };
  }

  determineRoute() {
    const { auth, location, isSubscriptionEnabled } = this.props;

    const loginRedirect = getLoginRedirect(auth, location);

    if (loginRedirect) {
      return url.format(loginRedirect);
    }

    if (location.pathname === PAYMENT_PATH && !isSubscriptionEnabled) {
      const query = parse(location?.search || '');
      return url.format({
        pathname: ACCOUNT_PATH,
        query,
      });
    }
  }

  render() {
    return this.state.redirectRoute ? (
      <BaseLoader />
    ) : (
      <AccountSettingsLoader {...this.props} />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  const isStripeEnabled = shouldUseStripe(state);
  return {
    auth,
    isStripeEnabled,
    isSubscriptionEnabled: selectIsSubscriptionEnabled(state),
  };
}

export default connect(mapStateToProps)(AccountSettingsRoute);

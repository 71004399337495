import { AB_TEST_IDS } from '../constants/experiments';
import { PAYPAL_DISABLED_COUNTRY_LIST } from '../constants/experiments/payment';
import { RECURLY_COUPON_PREFIXES } from '../constants/experiments/subscription';

function commaDelimitedStringToArray(setting) {
  return setting?.split(',');
}

export const configApiOptions = {
  commands: 'ads,autoupdate,unlock,terms,location',
  customTransforms: {
    settingTransforms: {
      [PAYPAL_DISABLED_COUNTRY_LIST]: commaDelimitedStringToArray,
      [RECURLY_COUPON_PREFIXES]: commaDelimitedStringToArray,
      [AB_TEST_IDS]: String,
    },
  },
};

import { selectIsAuthenticated } from 'src/common/selectors/auth';
import { selectIsSubscriptionEnabled } from 'src/common/selectors/config';
import fetchSubscriptionIfNeeded from '../../actions/connectors/fetchSubscriptionIfNeeded';
import connectAsync from '../../utils/connectAsync';
import fetchPartner from '../../utils/connectors/fetchPartner';
import fetchProducts from '../../utils/connectors/fetchProducts';
import SubscribeRoute from './SubscribeRoute';

function fetchProductsIfNeeded({ store, location }) {
  const state = store.getState();
  if (!selectIsSubscriptionEnabled(state) || !selectIsAuthenticated(state)) {
    return null;
  }
  return fetchProducts(location);
}

export default connectAsync([
  fetchSubscriptionIfNeeded,
  fetchProductsIfNeeded,
  fetchPartner,
])(SubscribeRoute);

import {
  selectIsLandingProductFetchDisabled,
  selectIsSubscriptionEnabled,
} from 'src/common/selectors/config';
import { getCarouselItemsIfNeeded } from '../../actions/heroCarousel';
import { selectIsDiscord } from '../../selectors/app';
import connectAsync from '../../utils/connectAsync';
import fetchProducts from '../../utils/connectors/fetchProducts';
import LandingLoader from './LandingLoader';

// exported for testing purposes
export function fetchProductsIfNeeded({ store, location }) {
  const state = store.getState();
  const isDiscord = selectIsDiscord(state);

  if (isDiscord) {
    return;
  }

  if (
    selectIsSubscriptionEnabled(state) &&
    !selectIsLandingProductFetchDisabled(state)
  ) {
    return fetchProducts(location);
  }
  return null;
}

export default connectAsync([getCarouselItemsIfNeeded, fetchProductsIfNeeded])(
  LandingLoader,
);

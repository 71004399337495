import flow from 'lodash/flow';
import { createRadioProfileContext } from '../routes/utils/getProfileContext';
import getRadioContext from '../routes/utils/getRadioContext';
import connectAsync from '../utils/connectAsync';
import fetchCategory from '../utils/connectors/fetchCategory';
import fetchProfile from '../utils/connectors/fetchProfile';

// export for testing
export function mapStateToProps(state, ownProps) {
  const routeContext = ownProps.routeProps.guideContext;
  const { guideId } = routeContext || {};
  const { profiles, categories } = state;

  const isCategoryGuideItem = !!categories[guideId];
  const guideItem = profiles[guideId] || categories[guideId];

  return { guideItem, isCategoryGuideItem };
}

export default function (Component) {
  return flow(
    connectAsync(
      [fetchProfile(createRadioProfileContext), fetchCategory(getRadioContext)],
      mapStateToProps,
    ),
  )(Component);
}

import classnames from 'clsx';
import PropTypes from 'prop-types';
import css from './close-button.module.scss';

const CloseButton = ({
  id = '',
  iconKey = 'dialogClose',
  styles = {},
  testId = 'icon-cross',
  className = '',
  useFontIcon = false,
  hasDarkTheme,
  handleDialogClose,
}) => {
  let closeIconClassName;

  if (useFontIcon) {
    closeIconClassName = classnames(
      className,
      hasDarkTheme ? css.closeButtonDark : css.closeButton,
    );
  } else {
    closeIconClassName = classnames(
      className,
      hasDarkTheme ? css.closeButtonSvgDark : css.closeButtonSvg,
    );
  }

  return (
    <button type="button" className={css.button} onClick={handleDialogClose}>
      <i
        id={id}
        key={iconKey}
        className={closeIconClassName}
        style={styles}
        data-testid={testId}
      />
    </button>
  );
};

CloseButton.propTypes = {
  id: PropTypes.string,
  iconKey: PropTypes.string,
  styles: PropTypes.object,
  testId: PropTypes.string,
  className: PropTypes.string,
  useFontIcon: PropTypes.bool,
  hasDarkTheme: PropTypes.bool,
  handleDialogClose: PropTypes.func.isRequired,
};

export default CloseButton;

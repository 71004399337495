import PropTypes from 'prop-types';
/* eslint-disable max-len */

const SearchIcon = ({
  dataTestId = 'searchIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  strokeWidth = 2,
  onClick,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0003 22.9998L17.35 17.3496"
      stroke={fill}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
    <path
      d="M13.0603 19.1205C16.4072 19.1205 19.1205 16.4072 19.1205 13.0603C19.1205 9.71327 16.4072 7 13.0603 7C9.71327 7 7 9.71327 7 13.0603C7 16.4072 9.71327 19.1205 13.0603 19.1205Z"
      stroke={fill}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
  </svg>
);
SearchIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default SearchIcon;

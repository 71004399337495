import url from 'url';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Component } from 'react';

import { PAYMENT_PATH, RENEW_PATH } from '../../constants/paths';
import { PAYMENT_FLOW } from '../../constants/subscription/flow';
import { selectSubscriptionProviderName } from '../../selectors/me';
import getLoginRedirect from '../../utils/auth/getLoginRedirect';
import { isSubscribePairPath } from '../../utils/auth/partnerAuthFlowHelpers';
import getCouponRedirect from '../../utils/subscription/getCouponRedirect';
import getIsRenewalEligible from '../../utils/subscription/getIsRenewalEligible'; // exported for unit testing
import hasActiveSubscription from '../../utils/subscription/hasActiveSubscription';
import subscribedThroughWeb from '../../utils/subscription/subscribedThroughWeb';
import { BaseLoader } from '../Loader';
import PairingSubscribeLoader from '../auth/pairing/PairingSubscribeLoader';
import SubscribeLoader from './SubscribeLoader';

// exported for unit testing
export class SubscribeRoute extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    me: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    isRenewalEligible: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { history, location } = props;
    const onSubscribePair = isSubscribePairPath(location?.pathname);

    const routeTo = this.determineRoute(onSubscribePair);
    if (routeTo) {
      history.replace(routeTo);
    }
    this.state = {
      routeTo,
      onSubscribePair,
    };
  }

  determineRoute(onSubscribePair) {
    const { auth, me, location, isRenewalEligible } = this.props;

    const loginRedirect = getLoginRedirect(
      auth,
      location,
      !onSubscribePair ? PAYMENT_FLOW : '',
    );
    if (loginRedirect) {
      return url.format(loginRedirect);
    }

    const couponRedirect = getCouponRedirect(me, location);
    if (couponRedirect) {
      return url.format(couponRedirect);
    }

    const hasActiveSub = hasActiveSubscription(me);
    const providerName = selectSubscriptionProviderName({ me });
    const isSubscribedThroughWeb = subscribedThroughWeb(providerName);
    const wasPreviouslySubscribedOnWeb =
      !hasActiveSub && isSubscribedThroughWeb;

    if (
      onSubscribePair ||
      (!hasActiveSub && !wasPreviouslySubscribedOnWeb) ||
      (wasPreviouslySubscribedOnWeb && !isRenewalEligible)
    ) {
      return null;
    }

    const pathname = isRenewalEligible ? RENEW_PATH : PAYMENT_PATH;

    return url.format({
      pathname,
      search: location?.search,
    });
  }

  render() {
    const { onSubscribePair, routeTo } = this.state;
    const componentToRender = onSubscribePair ? (
      <PairingSubscribeLoader {...this.props} />
    ) : (
      <SubscribeLoader {...this.props} />
    );

    return routeTo ? <BaseLoader /> : componentToRender;
  }
}

export function mapStateToProps(state) {
  const { auth, me } = state;
  return {
    auth,
    me,
    isRenewalEligible: getIsRenewalEligible(state),
  };
}

export default connect(mapStateToProps)(SubscribeRoute);

import url from 'url';
import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import withAuthHandling from '../../decorators/auth/withAuthHandling';
import { selectIsAuthenticated } from '../../selectors/auth';
import unauthRedirectHandler from '../../utils/auth/unauthRedirectHandler';
import getFavoritesPathname from '../../utils/paths/getFavoritesPathname';
import canShowFavorites from '../favorites/utils/canShowFavorites';
import UserLoader from './UserLoader';

class UserRoute extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    breakpoint: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    me: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const routeTo = this.evaluateRoute();
    if (routeTo) {
      props.history.replace(routeTo);
    }
    this.state = { routeTo };
  }

  componentDidUpdate(prevProps) {
    if (
      selectIsAuthenticated(this.props) &&
      !selectIsAuthenticated(prevProps)
    ) {
      const routeTo = this.evaluateRoute();
      if (routeTo) {
        this.props.history.replace(routeTo);
      }
      this.state = { routeTo };
    }
  }

  get userName() {
    return get(this.props, 'match.params.userName', '');
  }

  evaluateRoute() {
    const userName = this.userName;

    if (canShowFavorites(this.props, userName)) {
      return url.format({
        ...this.props.location,
        pathname: getFavoritesPathname(userName),
      });
    }

    return null;
  }

  render() {
    return (
      <UserLoader
        userName={this.userName}
        breakpoint={this.props.breakpoint}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    me: state.me,
    auth: state.auth,
  };
}

export default flow(
  withAuthHandling({ unauthRedirectHandler }),
  connect(mapStateToProps),
)(UserRoute);

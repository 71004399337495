import {
  DEVICES_TABNAME,
  GENERAL_TABNAME,
  SUBSCRIPTION_TABNAME,
} from 'src/common/constants/localizations/settings';

export const generalTab = GENERAL_TABNAME;
export const devicesTab = DEVICES_TABNAME;
export const subscriptionTab = SUBSCRIPTION_TABNAME;

export default function getLinks(showSubscriptionTab) {
  const links = [
    { name: generalTab, path: '/account/' },
    { name: devicesTab, path: '/devices/' },
  ];

  if (showSubscriptionTab) {
    links.push({ name: subscriptionTab, path: '/payment/' });
  }

  return links;
}

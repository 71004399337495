import isFunction from 'lodash/isFunction';
import TextField from 'material-ui/TextField';
import PropTypes from 'prop-types';
import { Component } from 'react';
import keyCodes from '../../../constants/keyCodes';
import withControlledValue from '../../../decorators/form/withControlledValue';
import withFlattenedProps from '../../../decorators/form/withFlattenedProps';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import styleGuide from '../../../styles/styleGuide';
import css from './input.module.scss';

class Input extends Component {
  static propTypes = {
    id: PropTypes.string,
    dataTestId: PropTypes.string,
    className: PropTypes.string,
    inputErrorStyle: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
    hintText: PropTypes.string,
    style: PropTypes.object,
    hintStyle: PropTypes.object,
    isPairingFlow: PropTypes.bool,
    inputStyle: PropTypes.object,
    finalStyleOverride: PropTypes.object,
    inputFocusStyle: PropTypes.object,
    replaceStyle: PropTypes.bool,
    formFieldMeta: PropTypes.object,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    localizeErrorMessages: PropTypes.bool,
    inlineErrorStyles: PropTypes.object,
    isRequired: PropTypes.bool,
    inlineErrors: PropTypes.bool,
    floatingLabel: PropTypes.bool,
    floatingLabelStyle: PropTypes.object,
    floatingLabelShrinkStyle: PropTypes.object,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onEnterPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    isBold: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  componentDidMount() {
    this.executeMaterialUiHack();
  }

  state = {
    isFocused: false,
  };

  onFocus = (e) => {
    this.setState({ isFocused: true });

    if (this.props.autoFocus) {
      // hack for placing focused cursor at the end of input
      const val = e.target.value;
      e.target.value = ''; // eslint-disable-line no-param-reassign
      e.target.value = val; // eslint-disable-line no-param-reassign
    }

    if (isFunction(this.props.onFocus)) {
      this.props.onFocus();
    }
  };

  onBlur = () => {
    this.setState({ isFocused: false });

    if (isFunction(this.props.onBlur)) {
      this.props.onBlur();
    }
  };

  handleKeyDown = (event) => {
    // for Inputs that are not wrapped by form
    const { onEnterPress, onKeyDown, value } = this.props;
    if (
      (event.key === 'Enter' ||
        event.code === 'Enter' ||
        event.keyCode === keyCodes.ENTER) &&
      onEnterPress
    ) {
      onEnterPress(event, value);
    }

    if (isFunction(onKeyDown)) {
      onKeyDown(event, value);
    }
  };

  handleErrorLocalization = (error) => {
    const { getLocalizedText } = this.context;

    return Array.isArray(error)
      ? getLocalizedText(error[0], error[1]?.[0])
      : getLocalizedText(error);
  };

  /**
   *  Some of our Material Input Field designs use floating / shrinking labels, which can fall into an 'overlapping' state
   *  There is not a native solution for this problem. By manually placing the label element after the input element,
   *  we're able to target the label as an immediate sibling of the input with CSS, and apply a manual transform.
   *  See `input:-webkit-autofill + label` styles in auth.module.scss
   *  TODO: Once we upgrade or replace MUI, we can remove this hack.
   *
   *  See here for more details: https://mui.com/components/text-fields/#limitations, https://github.com/mui/material-ui/issues/14427, https://github.com/mui/material-ui/issues/718
   */
  executeMaterialUiHack() {
    // NOTE: For this hack to work, we need to always pass in an id property to the react-final-form Field component
    try {
      const { floatingLabel, id } = this.props;

      if (floatingLabel && id) {
        const inputElement = document.getElementById(id);
        const parentElement = inputElement?.parentElement;
        const labelElement = parentElement?.querySelector('label');

        if (parentElement && labelElement) {
          parentElement.removeChild(labelElement);
          parentElement.appendChild(labelElement);
        }
      }
    } catch (e) {
      // noop
    }
  }

  render() {
    const {
      id,
      dataTestId,
      inputErrorStyle,
      className,
      name,
      type,
      hintText,
      style,
      hintStyle,
      inputStyle,
      finalStyleOverride,
      inputFocusStyle,
      inputFocusedStyle,
      replaceStyle,
      formFieldMeta,
      disabled,
      isRequired,
      inlineErrorStyles,
      localizeErrorMessages,
      label,
      inlineErrors,
      floatingLabel,
      floatingLabelStyle,
      floatingLabelShrinkStyle,
      autoFocus,
      isBold,
      // eslint-disable-next-line no-unused-vars
      onEnterPress,
      ...other
    } = this.props;
    const showErrorState =
      formFieldMeta && formFieldMeta.touched && formFieldMeta.invalid;
    const showFilledState =
      !showErrorState &&
      formFieldMeta &&
      (formFieldMeta.active || formFieldMeta.dirty);

    let finalStyle =
      replaceStyle && style
        ? style
        : {
            ...styleGuide.textField,
            ...style,
          };

    let finalInputStyle = isBold
      ? {
          ...inputStyle,
          ...styleGuide.textFieldInputBold,
        }
      : inputStyle;

    if (disabled) {
      finalStyle = {
        ...finalStyle,
        ...styleGuide.disabledState,
      };
    } else if (showErrorState) {
      finalStyle = {
        ...finalStyle,
        ...styleGuide.errorState,
        ...inputErrorStyle,
      };
    } else if (showFilledState) {
      finalStyle = {
        ...finalStyle,
      };
      finalInputStyle = {
        ...finalInputStyle,
        ...styleGuide.textFieldFocused,
        ...inputFocusStyle,
      };
    }

    // finalStyle can take on different styling for many states
    // adding this so we can bypass every state, and unconditionally apply a set of styles
    finalStyle = {
      ...finalStyle,
      ...finalStyleOverride,
    };

    const text = hintText || label;

    // hiddenLabel is used for web accessibility
    // WCAG asks for all input fields to have an associated labelElement, even if the label element is hidden.
    // this helps screen readers identify form elements on the page
    // more recent versions of material offer a hiddenLabel config option on the textField component itself
    // MUI v0, however, does not
    const hiddenLabel = text || name || dataTestId || id;

    if (inlineErrors) {
      const errorText = Array.isArray(formFieldMeta.error) ? (
        <div>
          {formFieldMeta.error.map((error, i) => (
            <p data-testid="error" key={i}>
              {localizeErrorMessages
                ? this.handleErrorLocalization(error)
                : error}
            </p>
          ))}
        </div>
      ) : (
        formFieldMeta.error
      );

      if (floatingLabel) {
        const finalFloatingLabelStyle = {
          ...styleGuide.label,
          ...floatingLabelStyle,
        };

        const finalFloatingLabelShrinkStyle = {
          ...styleGuide.floatingLabelShrink,
          ...floatingLabelShrinkStyle,
        };

        // col- classes need extra margin-bottom for this style
        return (
          <TextField
            errorText={
              formFieldMeta.touched && formFieldMeta.invalid && errorText
            }
            errorStyle={{
              ...styleGuide.inlineError,
              ...inlineErrorStyles,
            }}
            id={id}
            data-testid={dataTestId}
            className={className}
            name={name}
            type={type}
            hintText={hintText}
            floatingLabelText={isRequired ? `${label} *` : label}
            floatingLabelStyle={finalFloatingLabelStyle}
            floatingLabelFocusStyle={finalFloatingLabelShrinkStyle}
            style={finalStyle}
            underlineShow={false}
            hintStyle={{
              ...styleGuide.textFieldHint,
              ...hintStyle,
            }}
            inputStyle={{
              ...styleGuide.textFieldInput,
              ...finalInputStyle,
              marginTop: 0,
            }}
            disabled={disabled}
            {...other}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.handleKeyDown}
          />
        );
      }

      return (
        <>
          <label
            htmlFor={id}
            data-testid="hiddenInputLabel"
            className={css.srOnly}
          >
            {hiddenLabel}
          </label>
          <TextField
            errorText={
              formFieldMeta.touched && formFieldMeta.invalid && errorText
            }
            errorStyle={{
              ...styleGuide.inlineError,
              ...inlineErrorStyles,
            }}
            id={id}
            data-testid={dataTestId}
            className={className}
            name={name}
            type={type}
            hintText={text}
            style={finalStyle}
            underlineShow={false}
            hintStyle={{
              ...styleGuide.textFieldHint,
              ...hintStyle,
            }}
            inputStyle={{
              ...styleGuide.textFieldInput,
              ...finalInputStyle,
            }}
            disabled={disabled}
            {...other}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.handleKeyDown}
          />
        </>
      );
    }

    return (
      <>
        <label
          htmlFor={id}
          data-testid="hiddenInputLabel"
          className={css.srOnly}
        >
          {hiddenLabel}
        </label>
        <TextField
          autoFocus={autoFocus}
          id={id}
          data-testid={dataTestId}
          className={className}
          name={name}
          type={type}
          // TODO: Utilize `Text` here when all components that utilize this one are localized
          hintText={text}
          style={finalStyle}
          underlineShow={false}
          hintStyle={{
            ...styleGuide.textFieldHint,
            ...hintStyle,
          }}
          inputStyle={{
            ...styleGuide.textFieldInput,
            ...finalInputStyle,
          }}
          disabled={disabled}
          {...other}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.handleKeyDown}
        />
      </>
    );
  }
}

export default withFlattenedProps(Input);
export const ControlledInput = withControlledValue(Input);

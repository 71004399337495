import get from 'lodash/get';
import { selectUser } from 'src/common/actions/users';
import connectAsync from 'src/common/utils/connectAsync';
import UserRoute from './UserRoute';

function getSelectedUser(params) {
  const userName = get(params, 'match.params.userName');
  if (userName) {
    return selectUser({ contentId: userName });
  }
  return null;
}

export default connectAsync([getSelectedUser])(UserRoute);

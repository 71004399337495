import { fetchCategory } from 'src/common/actions/browse';
import fetchProfileIfInQuery from 'src/common/actions/connectors/fetchProfileIfInQuery';

import { PAYMENT_COMPLETE_GUIDE_ID } from '../../constants/categories';
import connectAsync from '../../utils/connectAsync';

import PaymentCompletionRoute from './PaymentCompletionRoute';

function fetchCompletionPageItems() {
  return fetchCategory({ guideId: PAYMENT_COMPLETE_GUIDE_ID });
}

export default connectAsync([fetchCompletionPageItems, fetchProfileIfInQuery])(
  PaymentCompletionRoute,
);

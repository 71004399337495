import { selectIsSubscriptionEnabled } from 'src/common/selectors/config';
import fetchSubscriptionIfNeeded from '../../actions/connectors/fetchSubscriptionIfNeeded';
import { selectIsAuthenticated } from '../../selectors/auth';
import connectAsync from '../../utils/connectAsync';
import fetchProducts from '../../utils/connectors/fetchProducts';
import RenewRoute from './RenewRoute';

// exported for testing purposes
export function fetchProductsIfNeeded({ store, location }) {
  const state = store.getState();
  if (!selectIsSubscriptionEnabled(state) || !selectIsAuthenticated(state)) {
    return null;
  }
  return fetchProducts(location, true);
}

export default connectAsync([fetchSubscriptionIfNeeded, fetchProductsIfNeeded])(
  RenewRoute,
);

import classNames from 'clsx';
import Dialog from 'material-ui/Dialog';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import isServer from '../../../utils/isServer';
import CloseButton from '../button/CloseButton';
import css from './common-dialog.module.scss';
import darkThemeCss from './dark-theme-dialog.module.scss';
import { commonDialogStyles } from './styles';

const defaultDialogTitleStyles = {
  border: null,
  textAlign: 'center',
};

/**
 * The MUI Dialog component renders and injects its children outside of the React tree hierarchy.
 * This component uses a Portal to allow connected components in the children prop to access the
 * store provider in the tree.
 *
 * @param {bool} dialogOpen - Controls whether the Dialog is opened or not.
 * @param {func} handleDialogClose - Close handler for corner close button
 * @param {node} children - Inherited children nodes
 * @param {object} dialogContentStyle - Overrides the inline-styles of the content container.
 * @param {string} bodyClassName - The className to add to the content's root element under the title.
 * @param {string} contentClassName - The className to add to the content container.
 * @param {bool} hideCornerClose - Controls corner close button visibility
 * @param {object} bodyStyle - Overrides the inline-styles of the content's root element under
 *  the title.
 * @param {object} titleStyle - Overrides the inline-styles of the title's root container element.
 * @param {bool} disableOverlayClickToClose - Force the user to use one of the actions in the
 *  Dialog. Clicking outside the Dialog will not trigger the handleDialogClose handler.
 * @param {object} closeIconAttributes - Additional props for corner close button
 * @param {bool} hasDarkTheme - Additional prop for corner close button
 * @param {object} other - Rest props for Dialog (https://v0.material-ui.com/#/components/dialog#properties)
 * @param {bool} useWideModalWidth - Toggles narrow and wide modal widths
 * @param {bool} enableFullScreen - Enables a full screen dialog
 * @param {object} rootStyle - Styles the root container
 */
const CommonDialog = ({
  dialogOpen,
  handleDialogClose,
  children,
  dialogContentStyle,
  bodyClassName,
  contentClassName,
  hideCornerClose,
  bodyStyle,
  titleStyle,
  disableOverlayClickToClose,
  closeIconAttributes = {},
  hasDarkTheme,
  useWideModalWidth,
  enableFullScreen,
  rootStyle,
  ...other
}) => {
  const [portalEl, setPortalEl] = useState(null);
  const { narrowContent, wideContent, body, darkTheme } = commonDialogStyles;
  const contentStyle = {
    ...(useWideModalWidth ? wideContent : narrowContent),
    ...dialogContentStyle,
  };
  const bodyStyles = {
    ...body,
    ...(hasDarkTheme && darkTheme.body),
    ...bodyStyle,
  };
  const contentClassNames = classNames(css.content, contentClassName, {
    [css.contentFullScreen]: enableFullScreen,
  });
  const bodyClassNames = classNames(css.dialogBody, bodyClassName, {
    [css.bodyFullScreen]: enableFullScreen,
    [darkThemeCss.dialogBody]: hasDarkTheme,
  });

  return (
    <>
      <Dialog
        contentStyle={contentStyle}
        bodyStyle={bodyStyles}
        titleStyle={{ ...defaultDialogTitleStyles, ...titleStyle }}
        // modal defaults to false, can change to true if want to disable overlay click to close
        modal={disableOverlayClickToClose}
        open={dialogOpen}
        onRequestClose={handleDialogClose}
        autoScrollBodyContent
        bodyClassName={bodyClassNames}
        contentClassName={contentClassNames}
        style={rootStyle}
        {...other}
      >
        {!hideCornerClose && (
          <CloseButton
            hasDarkTheme={hasDarkTheme}
            handleDialogClose={handleDialogClose}
            {...closeIconAttributes}
          />
        )}
        <div
          data-testid="dialogPortalRef"
          style={{ height: '100%' }}
          ref={(el) => setPortalEl(el)}
        />
      </Dialog>
      {!isServer() && portalEl
        ? ReactDOM.createPortal(children, portalEl)
        : null}
    </>
  );
};

CommonDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  dialogContentStyle: PropTypes.object,
  bodyClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  hideCornerClose: PropTypes.bool,
  bodyStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  disableOverlayClickToClose: PropTypes.bool,
  closeIconAttributes: PropTypes.object,
  hasDarkTheme: PropTypes.bool,
  useWideModalWidth: PropTypes.bool,
  enableFullScreen: PropTypes.bool,
  rootStyle: PropTypes.object,
};

CommonDialog.defaultProps = {
  disableOverlayClickToClose: false,
};

export default CommonDialog;

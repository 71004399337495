import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HOME_BROWSE_PATH, ROOT_PATH } from '../../constants/paths';
import LandingLoader from '../landing/LandingLoader';
import TuneInMarquee from '../shared/svgIcons/TuneInMarquee';

import css from './tune-in-logo.module.scss';

const TuneInLogo = ({ unlinkedLogo, iconProps, isDesktop }) => {
  if (unlinkedLogo) {
    return (
      <div className={css.link} data-testid="unlinkedLogo">
        <TuneInMarquee width="75px" {...iconProps} />
      </div>
    );
  }

  const url = isDesktop ? HOME_BROWSE_PATH : ROOT_PATH;
  return (
    <Link
      id="tuneInLogoHeader"
      to={url}
      className={css.link}
      onMouseOver={() => LandingLoader.preload()}
    >
      <TuneInMarquee width="75px" {...iconProps} />
    </Link>
  );
};

TuneInLogo.propTypes = {
  unlinkedLogo: PropTypes.bool,
  iconProps: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default TuneInLogo;

import url from 'url';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import getLoginRedirect from '../../utils/auth/getLoginRedirect';
import { BaseLoader } from '../Loader';

import {
  PAYMENT_PATH,
  RENEW_PATH,
  SUBSCRIBE_PATH,
} from '../../constants/paths';
import getCanUpdateSub from '../../utils/subscription/getCanUpdateSub';
import getIsRenewalEligible from '../../utils/subscription/getIsRenewalEligible';
import hasActiveSubscription from '../../utils/subscription/hasActiveSubscription';
import PaymentInformationLoader from './PaymentInformationLoader';

class PaymentInformationRoute extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    canUpdateSub: PropTypes.bool.isRequired,
    canRenewSub: PropTypes.bool.isRequired,
    hasActiveSub: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const redirectRoute = this.determineRoute();

    if (redirectRoute) {
      this.props.history.replace(redirectRoute);
    }
    this.state = { redirectRoute };
  }

  determineRoute() {
    const { auth, location, canUpdateSub, canRenewSub, hasActiveSub } =
      this.props;

    const loginRedirect = getLoginRedirect(auth, location);

    if (loginRedirect) {
      return url.format(loginRedirect);
    }

    if (canUpdateSub) {
      return null;
    }

    const renewOrSubscribePath = canRenewSub ? RENEW_PATH : SUBSCRIBE_PATH;
    // if we cannot update and there is an active sub, it means the user subscribed on another client,
    // so show the payment page
    const pathname = hasActiveSub ? PAYMENT_PATH : renewOrSubscribePath;
    return url.format({
      pathname,
      search: location?.search,
    });
  }

  render() {
    return this.state.redirectRoute ? (
      <BaseLoader />
    ) : (
      <PaymentInformationLoader {...this.props} />
    );
  }
}

function mapStateToProps(state) {
  const { auth, me } = state;
  const canUpdateSub = getCanUpdateSub(state);
  const canRenewSub = getIsRenewalEligible(state);
  const hasActiveSub = hasActiveSubscription(me);

  return {
    auth,
    canUpdateSub,
    canRenewSub,
    hasActiveSub,
  };
}

export default connect(mapStateToProps)(PaymentInformationRoute);

import flow from 'lodash/flow';
import withAdScreenRequest from '../../decorators/ads/withAdScreenRequest';
import connectAsync from '../../utils/connectAsync';

import { createVanityProfileContext } from '../../routes/utils/getProfileContext';
import fetchAudioClip from '../../utils/connectors/fetchAudioClip';
import fetchProfile from '../../utils/connectors/fetchProfile';
import fetchTopic from '../../utils/connectors/fetchTopic';
import ProfileLoader from './ProfileLoader';

export default flow(
  withAdScreenRequest(() => 'profile'),
  connectAsync([
    fetchProfile(createVanityProfileContext),
    fetchTopic(createVanityProfileContext),
    fetchAudioClip(createVanityProfileContext),
  ]),
)(ProfileLoader);

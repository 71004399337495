import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { escapeQuery, getSearchUrl } from '../../utils/search/getSearchUrl';
import { ControlledInput } from '../shared/form/Input';
import LegacySearchIcon from '../shared/svgIcons/LegacySearchIcon';
import SearchIcon from '../shared/svgIcons/SearchIcon';
import css from './searchbar.module.scss';

const fieldStyle = {
  height: 42,
  paddingLeft: 20,
  borderRadius: 5,
  width: '100%',
};

// exported for testing
export function Searchbar({
  useLegacyIcon,
  searchBarClassName,
  initialValue,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  iconContainerClassName,
  autoFocus,
  iconProps,
  hintText,
  hintStyle,
  history,
  onSubmit,
}) {
  const SearchIconComponent = useLegacyIcon ? LegacySearchIcon : SearchIcon;

  const onUserSubmit = useCallback(
    (event, value) => {
      if (!value) {
        return;
      }

      const query = escapeQuery(value);
      history.push(getSearchUrl(query));
      if (onSubmit) onSubmit(value);
    },
    [history, onSubmit],
  );

  const handleEnterPress = useCallback(
    (event, value) => {
      event.preventDefault();
      onUserSubmit(event, value);
    },
    [onUserSubmit],
  );

  return (
    <div
      data-testid="searchBar"
      className={classNames(css.wrapper, searchBarClassName)}
    >
      <div className={iconContainerClassName}>
        <SearchIconComponent
          id="searchIcon"
          key="searchIcon"
          dataTestId="searchBarIcon"
          onClick={onUserSubmit}
          {...iconProps}
        />
      </div>
      <ControlledInput
        hintText={hintText}
        hintStyle={hintStyle}
        autoFocus={autoFocus}
        replaceStyle
        id="searchField"
        data-testid="searchBarInput"
        className={css.field}
        style={fieldStyle}
        onEnterPress={handleEnterPress}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        initialValue={initialValue}
        key={initialValue}
        placeholder={placeholder}
        autoComplete="off"
      />
    </div>
  );
}

export default withRouter(Searchbar);

Searchbar.propTypes = {
  history: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  useLegacyIcon: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hintText: PropTypes.string,
  hintStyle: PropTypes.object,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  iconProps: PropTypes.object,
  placeholder: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  searchBarClassName: PropTypes.string,
};

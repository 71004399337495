import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { MUSIC_BROWSE_PATH } from 'src/common/constants/paths';
import withAdScreenRequest from '../../decorators/ads/withAdScreenRequest';
import getRadioContext from '../../routes/utils/getRadioContext';
import connectAsync from '../../utils/connectAsync';
import fetchAudioClip from '../../utils/connectors/fetchAudioClip';
import fetchProfile from '../../utils/connectors/fetchProfile';
import fetchTopic from '../../utils/connectors/fetchTopic';
import { isProfile } from '../../utils/guideItemTypes';
import { BaseLoader } from '../Loader';
import Error404 from '../errors/404';
import ProfileLoader from './ProfileLoader';

class ProfileRoute extends Component {
  static propTypes = {
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const state = {};

    const { history, location } = props;
    if (
      location.pathname.startsWith('/artist/') ||
      location.pathname.startsWith('/album/')
    ) {
      history.replace(MUSIC_BROWSE_PATH);

      state.isRedirecting = true;
    }

    this.state = state;
  }

  render() {
    const { guideId } = getRadioContext(this.props) || {};
    const { isRedirecting } = this.state;

    if (isRedirecting) {
      return <BaseLoader />;
    }

    if (isProfile(guideId)) {
      return <ProfileLoader {...this.props} />;
    }

    return <Error404 {...this.props} />;
  }
}

export default flow(
  withAdScreenRequest(() => 'profile'),
  connectAsync([
    fetchProfile(getRadioContext),
    fetchTopic(getRadioContext),
    fetchAudioClip(getRadioContext),
  ]),
)(ProfileRoute);

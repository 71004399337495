import get from 'lodash/get';
import {
  CUSTOM_SOURCE_QUERY_PARAM,
  DISCOUNT_QUERY_PARAM,
} from 'src/common/constants/queryParams';
import { trialUnitTypes } from '../../constants/subscription/coupon';
import parse from '../../utils/queryString/parse';

export const isMonthlyTrial = (product) =>
  !!product.discountFreeTrialUnit &&
  product.discountFreeTrialUnit.toLowerCase() ===
    trialUnitTypes.month.toLowerCase();

// is discounted using url param discount=true
export const isDiscountEnabled = (location) => {
  // Parse the query directly from the search string since
  // this utility may be used within top level route components
  // where the query object is not available on the client. See
  // this ticket, TUNE-10780, for details.
  const query = parse(get(location, 'search', ''));
  return get(query, DISCOUNT_QUERY_PARAM) === 'true';
};

// is discounted using url param discount=true
export const isCustomSourceEnabled = (location) => {
  // Parse the query directly from the search string since
  // this utility may be used within top level route components
  // where the query object is not available on the client. See
  // this ticket, TUNE-10780, for details.
  const query = parse(get(location, 'search', ''));
  return get(query, CUSTOM_SOURCE_QUERY_PARAM) === 'true';
};

import filter from 'lodash/filter';
import get from 'lodash/get';
import { validColorsArr } from 'src/common/constants/heroCarousel';
import containsIgnoreCase from 'src/common/utils/containsIgnoreCase';
import defaultCarouselElements from 'src/data/landing/carouselElements.json';
import { isAbsoluteUrl } from './isAbsoluteUrl';

export default function validateCarouselItems(
  carouselElements,
  shouldShowPremiumUpsell,
) {
  let index = 0;

  function iterateAndReturnFromDefault() {
    const itemToReturn = defaultCarouselElements[index];
    index++;
    if (itemToReturn) {
      return itemToReturn;
    }
    return null;
  }

  function isBgColorValid(hex) {
    return containsIgnoreCase(validColorsArr, hex);
  }

  function isValidTextLength(text) {
    const stringLength = get(text, 'length', 0);
    return stringLength > 0 && stringLength <= 20;
  }

  function isValidHref(href) {
    const hrefLength = get(href, 'length', 0);
    return hrefLength > 0 && isAbsoluteUrl(href);
  }

  const filteredCarouselElements = filter(
    carouselElements,
    (element) => !element.isCarouselUpsell || shouldShowPremiumUpsell,
  );

  return !filteredCarouselElements.length
    ? defaultCarouselElements
    : filteredCarouselElements.map((element) => {
        const hasImageUrlLength = isValidHref(element.imageUrl);
        const hasTinyImageUrlLength =
          element.isCarouselUpsell || isValidHref(element.tinyImgUrl);
        const isGuideItem = typeof element.guideItem === 'object';
        const hasValidHref = get(element, 'href.length', 0) > 0;

        if (
          !isGuideItem ||
          !hasValidHref ||
          !hasImageUrlLength ||
          !hasTinyImageUrlLength
        ) {
          return iterateAndReturnFromDefault();
        }
        if (
          !isValidTextLength(element.line1) ||
          !isValidTextLength(element.line2) ||
          !isValidTextLength(element.line3)
        ) {
          return iterateAndReturnFromDefault();
        }
        if (!isBgColorValid(element.bgColor)) {
          return iterateAndReturnFromDefault();
        }
        return element;
      });
}

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PAGE_LOAD_ERROR } from 'src/common/constants/localizations/app';
import { logClientError } from '../../actions/logging';
import { BaseLoader } from '../Loader';
import ErrorPage from '../errors/ErrorPage';

import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';

const errorLogMessage = 'BUNDLE LOAD ERROR: Failed to load bundle: ';

export function getBundleLoader(bundleName) {
  class BundleLoader extends Component {
    static propTypes = {
      error: PropTypes.any, // Any as I couldn't figure out exactly what error would be; so far, I
      // just know it's `null` if there is no error. The docs don't help here.
      pastDelay: PropTypes.bool,
      logClientError: PropTypes.func.isRequired,
    };

    static contextType = LocationAndLocalizationContext;

    componentDidMount() {
      this.logIfError(this.props.error);
    }

    componentDidUpdate(prevProps) {
      if (this.props.error !== prevProps.error) {
        this.logIfError(this.props.error);
      }
    }

    logIfError(error) {
      if (error) {
        // Logging here for analysis later
        const errorLogData = {
          message: `${errorLogMessage}${bundleName}`,
          context: {
            error,
            path: this.context.location.pathname,
          },
        };

        this.props.logClientError(errorLogData);
      }
    }

    render() {
      if (this.props.error) {
        return (
          <ErrorPage
            httpStatusCode={500}
            message={this.context.getLocalizedText(PAGE_LOAD_ERROR)}
          />
        );
      }
      if (this.props.pastDelay) {
        return <BaseLoader />;
      }

      return null;
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      ...bindActionCreators({ logClientError }, dispatch),
    };
  }

  return connect(null, mapDispatchToProps)(BundleLoader);
}

export default function createBundleLoader(bundleName) {
  if (!bundleName) {
    throw new Error('Please supply a `bundleName` value');
  }

  const ConnectedBundleLoader = getBundleLoader(bundleName);

  return <ConnectedBundleLoader />;
}

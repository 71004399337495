import flow from 'lodash/flow';
import get from 'lodash/get';
import { performPrepopulateSearch, performSearch } from '../../actions/search';
import {
  FM_SEARCH_PREPOPULATE_GUIDE_ID,
  LIVE_AUTOCOMPLETE_SUGGESTIONS_ENABLED,
} from '../../constants/experiments/search';
import { SEARCH_PATH } from '../../constants/paths';
import withAdScreenRequest from '../../decorators/ads/withAdScreenRequest';
import { selectExperiment } from '../../selectors/config';
import connectAsync from '../../utils/connectAsync';
import { getSearchScreenType } from '../utils/adScreenTypes';
import SearchLoader from './SearchLoader';
import getSearchQueryObj from './getSearchQueryObj';

function asyncSearch({ location, store, history }) {
  const searchQueryObj = getSearchQueryObj(location);
  const storeState = store.getState();

  const isAutocompleteSuggestionsEnabled = selectExperiment(
    storeState,
    LIVE_AUTOCOMPLETE_SUGGESTIONS_ENABLED,
    false,
  );

  if (
    isAutocompleteSuggestionsEnabled &&
    // Note: The autocomplete feature should work only on /search page when the user types the search term on it.
    location?.pathname === SEARCH_PATH &&
    history?.action === 'REPLACE'
  ) {
    searchQueryObj.autocomplete = true;
  }

  if (searchQueryObj?.query) {
    return performSearch(searchQueryObj);
  }
  const guideId = get(storeState, [
    'config',
    'autoupdate',
    FM_SEARCH_PREPOPULATE_GUIDE_ID,
  ]);
  return guideId && performPrepopulateSearch({ query: guideId });
}

export default flow(
  withAdScreenRequest(getSearchScreenType),
  connectAsync([asyncSearch]),
)(SearchLoader);

import { releaseUtils } from '@tunein/web-utils';
import vars from 'src/common/vars';
import { permanentCookieExpiration } from '../constants/cookies';
import { isDevBuild, isLocalEnv } from '../utils/environment';
import isServer from '../utils/isServer';

export const LOOPBACK_DOMAIN = '127.0.0.1';
const releaseVersion = releaseUtils.calculateReleaseVersion(RELEASE_VERSION);
const appOrigin = vars.get('GEMINI_ORIGIN');
const embedOrigin = vars.get('EMBED_ORIGIN');
const fordProductionOrigin = vars.get('FORD_PROD_ORIGIN');
const autoProductionOrigin = vars.get('AUTO_PROD_ORIGIN');
const discordProductionOrigin = vars.get('DISCORD_PROD_ORIGIN');
const secureOrigin = isDevBuild()
  ? `https://${appOrigin}:3443`
  : `https://${appOrigin}`;
const insecureOrigin = isDevBuild()
  ? `http://${appOrigin}:3000`
  : `http://${appOrigin}`;
const localApiAbsolutePath = `${secureOrigin}/api/v1`;
const localApi = isLocalEnv() || isServer() ? '/api/v1' : localApiAbsolutePath;
const discordProductionLocalApi = `https://${discordProductionOrigin}/api/v1`;
const DISCORD_CLIENT_ID = vars.get('DISCORD_CLIENT_ID');
const discordOriginsMap = {
  discord: 'https://discord.com',
  discordProxy: `https://${DISCORD_CLIENT_ID}.discordsays.com`,
};
const discordProxyPath = '.proxy';
const apiDomains = {
  graphQl: vars.get('GRAPH_QL_EXTERNAL'),
  feed: vars.get('FEED_ROOT_EXTERNAL'),
  opml: vars.get('OPML_ROOT_EXTERNAL'),
  event: vars.get('EVENTS_API_EXTERNAL'),
  sonos: vars.get('SONOS_ROOT_EXTERNAL'),
  coreApi: vars.get('CORE_API_EXTERNAL'),
  reports: vars.get('REPORT_ROOT_EXTERNAL'),
  api: vars.get('API_ROOT_EXTERNAL'),
  stream: vars.get('STREAM_ROOT_EXTERNAL'),
  radiomill: vars.get('RADIOMILL_ROOT_EXTERNAL'),
  local: localApi,
  discordProductionLocal: discordProductionLocalApi,
  loopback: `http://${LOOPBACK_DOMAIN}:${vars.get('PORT')}`,
};

export default {
  absoluteUrlRoot: 'https://tunein.com',
  secureOrigin,
  insecureOrigin,
  insecureEmbedOrigin: `http://${embedOrigin}`,
  discordOriginsMap,
  discordOrigins: Object.values(discordOriginsMap),
  discordProductionOrigin,
  // The `.proxy` path is required by the Discord proxy: https://discord.com/developers/docs/activities/development-guides#construct-a-full-url
  discordProxyPath,
  fordProductionOrigin,
  autoProductionOrigin,
  releaseVersion,
  api: {
    domains: apiDomains,
    partnerId: 'RadioTime',
    macDesktopPartnerId: '0O4y0Ibc',
    windowsDesktopPartnerId: 'gNC8hbia',
    mobileWebPartnerId: 'ze_q2*uy',
    version: releaseVersion,
  },
  discordApi: {
    domains: apiDomains,
    partnerId: '1fq8nYEB',
    version: releaseVersion,
  },
  fordApi: {
    domains: apiDomains,
    partnerId: 'oYbfCZPY',
    version: releaseVersion,
  },
  autoApi: {
    domains: apiDomains,
    partnerId: 'mAVpw2Be',
    version: releaseVersion,
  },
  cookies: {
    opts: {
      domain: isDevBuild() ? '' : vars.get('COOKIE_DOMAIN'),
      secure: true,
      httpOnly: true,
      sameSite: 'None',
      maxAge: permanentCookieExpiration - new Date().getTime(),
    },
    permanentExpirationMs: permanentCookieExpiration,
    expiration: vars.get('COOKIE_EXPIRATION'),
  },
  oauth: {
    google: {
      scope:
        'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      cookiePolicy: 'single_host_origin',
      prompt: 'select_account',
      api: 'https://apis.google.com/js/platform.js',
    },
    fb: {
      // `version` value should start with a `v`, but we're manually concatenating it in Gemini-Desktop (which inherits this config),
      // so please be careful not to manually add it here, too.
      version: '14.0',
      scope: 'public_profile,email',
      language: 'en_US',
      sdk: 'https://connect.facebook.net/en_US/sdk.js',
    },
    apple: {
      // Documentation for the options below can be found here:
      // https://developer.apple.com/documentation/signinwithapplejs/incorporating_sign_in_with_apple_into_other_platforms
      scope: 'name email',
      responseMode: 'form_post',
      responseType: 'code id_token',
      messageType: 'apple.oauth.callback',
      clientId: vars.get('APPLE_CLIENT_ID'),
      authorizationUrl: 'https://appleid.apple.com/auth/authorize',
      clientUrl: `${localApiAbsolutePath}/oauth/apple/authorize`,
      callbackUrl: `${localApiAbsolutePath}/oauth/apple/callback`,
    },
  },
  recaptchaV3SiteKey: vars.get('RECAPTCHA_V3_SITE_KEY'),
  streamFormats: 'mp3,aac,ogg,flash,html,hls,wma',
  desktopStreamFormats: 'mp3,aac,ogg,flash,hls,wma',
  mobileStreamFormats: 'mp3,aac,ogg,hls',
  discordStreamFormats: 'mp3,aac,ogg,hls',
  configCommands: 'ads,autoupdate,unlock,terms,location',
  autoConfigCommands: 'autoupdate,terms,location',
};

import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';

/* eslint-disable max-len */
const LegacySearchIcon = ({
  dataTestId,
  className,
  onClick,
  viewBox = '0 0 14 14',
  height = '100%',
  width = '100%',
  fill = cssVariables['--secondary-color-5'],
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
  >
    <title>Search Icon</title>
    <defs>
      <path
        d="M3.61930507,6.71417662 C3.61930507,5.0360309 4.94670371,3.6709317 6.57784587,3.6709317 C8.20964549,3.6709317 9.53704413,5.0360309 9.53704413,6.71417662 C9.53704413,8.39232234 8.20964549,9.75674542 6.57784587,9.75674542 C4.94670371,9.75674542 3.61930507,8.39232234 3.61930507,6.71417662 M14.1260696,12.7763259 L10.69219,9.24491773 C11.1247944,8.5038832 11.3785714,7.63979366 11.3785714,6.71417662 C11.3785714,3.99209174 9.22475371,1.77777778 6.57784587,1.77777778 C3.93159548,1.77777778 1.77777778,3.99209174 1.77777778,6.71417662 C1.77777778,9.4362615 3.93159548,11.6505755 6.57784587,11.6505755 C7.65541218,11.6505755 8.64750953,11.2793821 9.44894536,10.6607264 L12.8157648,14.1238458 C12.9439682,14.2550144 13.1517235,14.2550144 13.279927,14.1238458 L14.1260696,13.252995 C14.2542731,13.1218265 14.2542731,12.9081706 14.1260696,12.7763259"
        id="search-icon-path"
      />
    </defs>
    <g
      id="Components/SearchField"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-71.000000, -9.000000)"
    >
      <g id="Icons/Search" transform="translate(70.000000, 8.000000)">
        <rect id="Rectangle-Copy-5" x="0" y="0" width="16" height="16" />
        <mask id="search-icon-mask" fill={fill}>
          <use xlinkHref="#search-icon-path" />
        </mask>
        <use
          id="search-icon-use"
          fill={fill}
          fillRule="evenodd"
          xlinkHref="#search-icon-path"
        />
        <g
          id="Color/White"
          mask="url(#search-icon-mask)"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(-42.000000, -12.000000)" id="Box">
            <rect x="0" y="0" width="100" height="40" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LegacySearchIcon.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

export default LegacySearchIcon;

import PropTypes from 'prop-types';

import classNames from 'clsx';
import { OK } from '../../../constants/localizations/app';
import Text from '../../Text';
import PillButton from '../button/PillButton';
import CommonDialog from './CommonDialog';
import css from './dialog.module.scss';

const OkDialog = ({
  shouldShow,
  handleClose,
  content,
  heading,
  bodyStyle,
  dialogContentStyle,
  dataTestId,
  hideCornerClose,
  contentClassName,
}) => (
  <CommonDialog
    data-testid={dataTestId}
    key={dataTestId}
    dialogOpen={shouldShow}
    handleDialogClose={handleClose}
    dialogContentStyle={{ maxWidth: 420, ...dialogContentStyle }}
    bodyStyle={bodyStyle}
    overlayClassName="okDialogOverlay"
    hideCornerClose={hideCornerClose}
    disableOverlayClickToClose
  >
    {heading}
    {content && (
      <div
        className={classNames(css.dialogContent, contentClassName)}
        data-testid="contentWrapper"
      >
        {content}
      </div>
    )}
    <div>
      <PillButton
        className={css.button}
        id="dialogOkButton"
        dataTestId="dialogOkButton"
        label={<Text name={OK} />}
        onClick={handleClose}
        autoFocus
      />
    </div>
  </CommonDialog>
);

OkDialog.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.string,
  heading: PropTypes.node,
  dialogContentStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  dataTestId: PropTypes.string.isRequired,
  hideCornerClose: PropTypes.bool,
  contentClassName: PropTypes.string,
};

OkDialog.defaultProps = {
  hideCornerClose: true,
};

export default OkDialog;

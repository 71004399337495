import url from 'url';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import get from 'lodash/get';

import parse from 'src/common/utils/queryString/parse';
import { PAYMENT_PATH, SUBSCRIBE_PATH } from '../../constants/paths';
import { PAYMENT_FLOW } from '../../constants/subscription/flow';
import { selectSubscriptionProviderName } from '../../selectors/me';
import { selectCurrentSubStatus } from '../../selectors/subscription';
import getLoginRedirect from '../../utils/auth/getLoginRedirect';
import canRenewOrUpdateSubscription from '../../utils/subscription/canRenewOrUpdateSubscription';
import getCouponRedirect from '../../utils/subscription/getCouponRedirect';
import hasActiveSubscription from '../../utils/subscription/hasActiveSubscription';
import subscribedThroughWeb from '../../utils/subscription/subscribedThroughWeb';
import { currentSubscriptionStatus } from '../../utils/subscription/subscriptionStatus';
import { BaseLoader } from '../Loader';
import RenewLoader from './RenewLoader';

// exported for unit testing
export class RenewRoute extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    me: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    canRenewOrUpdateSub: PropTypes.bool.isRequired,
    hasActiveSub: PropTypes.bool.isRequired,
    isWebSubscription: PropTypes.bool.isRequired,
    isSubCancelledWithProvider: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const routeTo = this.determineRoute();
    if (routeTo) {
      this.props.history.replace(routeTo);
    }
    this.state = { routeTo };
  }

  determineRoute() {
    const {
      auth,
      me,
      location,
      canRenewOrUpdateSub,
      hasActiveSub,
      isWebSubscription,
      isSubCancelledWithProvider,
    } = this.props;

    const loginRedirect = getLoginRedirect(auth, location, PAYMENT_FLOW);
    if (loginRedirect) {
      return url.format(loginRedirect);
    }

    const couponRedirect = getCouponRedirect(me, location);
    if (couponRedirect) {
      return url.format(couponRedirect);
    }

    const query = parse(get(location, 'search', ''));

    if (hasActiveSub && (!isWebSubscription || !isSubCancelledWithProvider)) {
      return url.format({
        pathname: PAYMENT_PATH,
        query,
      });
    }

    if (!canRenewOrUpdateSub) {
      return url.format({
        pathname: SUBSCRIBE_PATH,
        query,
      });
    }

    return null;
  }

  render() {
    return this.state.routeTo ? (
      <BaseLoader />
    ) : (
      <RenewLoader {...this.props} />
    );
  }
}

function mapStateToProps(state) {
  const { auth, me } = state;
  const canRenewOrUpdateSub = canRenewOrUpdateSubscription(state);
  const hasActiveSub = hasActiveSubscription(me);
  const isWebSubscription = subscribedThroughWeb(
    selectSubscriptionProviderName(state),
  );
  const isSubCancelledWithProvider =
    selectCurrentSubStatus(state) === currentSubscriptionStatus.canceled;
  return {
    auth,
    me,
    canRenewOrUpdateSub,
    hasActiveSub,
    isWebSubscription,
    isSubCancelledWithProvider,
  };
}

export default connect(mapStateToProps)(RenewRoute);

export const isAdBlockEnabledPromise = Promise.withResolvers();

export function disableAdBlockCheck() {
  isAdBlockEnabledPromise.resolve(false);
}

// Method found here:
// https://jonathanmh.com/how-to-detect-ad-blockers-adblock-ublock-etc/
// Tested with uBlock and AdBlock...
export async function checkIsAdBlockEnabled() {
  try {
    await fetch(
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
      {
        method: 'HEAD',
        mode: 'no-cors',
      },
    );

    isAdBlockEnabledPromise.resolve(false);
  } catch {
    isAdBlockEnabledPromise.resolve(true);
  }

  return isAdBlockEnabledPromise.promise;
}

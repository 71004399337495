import url from 'url';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { PAYMENT_FLOW } from '../../constants/subscription/flow';
import getLoginRedirect from '../../utils/auth/getLoginRedirect';
import { BaseLoader } from '../Loader';
import PaymentCompletionLoader from './PaymentCompletionLoader';

// exported for unit testing
export class PaymentCompletionRoute extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const routeTo = this.determineRoute();
    if (routeTo) {
      this.props.history.replace(routeTo);
    }
    this.state = { routeTo };
  }

  determineRoute() {
    const { auth, location } = this.props;

    const loginRedirect = getLoginRedirect(auth, location, PAYMENT_FLOW);
    if (loginRedirect) {
      return url.format(loginRedirect);
    }
    return null;
  }

  render() {
    return this.state.routeTo ? (
      <BaseLoader />
    ) : (
      <PaymentCompletionLoader {...this.props} />
    );
  }
}

export function mapStateToProps(state) {
  const { auth } = state;
  return { auth };
}

export default connect(mapStateToProps)(PaymentCompletionRoute);

import get from 'lodash/get';
import { logClientError } from 'src/common/actions/logging';
import { fetchMeDevices, refreshMe } from 'src/common/actions/me';
import { getSubscription } from 'src/common/actions/subscription';
import tracking from 'src/common/constants/subscription/tracking';
import { ACCOUNT_DEVICES_PATH, PAYMENT_PATH } from '../../constants/paths';
import { selectSubscriptionProviderName } from '../../selectors/me';
import connectAsync from '../../utils/connectAsync';
import fetchProducts from '../../utils/connectors/fetchProducts';
import subscribedThroughWeb from '../../utils/subscription/subscribedThroughWeb';
import { currentSubscriptionStatus } from '../../utils/subscription/subscriptionStatus';
import AccountSettingsRoute from './AccountSettingsRoute';

// exported for testing purposes
export function fetchProductIfNeeded(location) {
  return async (dispatch, getState) => {
    const meProvider = selectSubscriptionProviderName(getState());
    if (subscribedThroughWeb(meProvider)) {
      try {
        await dispatch(getSubscription());
      } catch (error) {
        dispatch(
          logClientError({
            message: tracking.messages.getSubscriptionError,
            context: {
              error,
            },
          }),
        );
      }
      const currentSubStatus = get(getState(), 'subscription.current.status');
      // if sub state in recurly is active then we already got the product info in getSubscription
      if (currentSubStatus === currentSubscriptionStatus.active) {
        return null;
      }
      // otherwise we need to get the renew skus
      return dispatch(fetchProducts(location, true));
    }
    // if user didn't have a recurly sub, then we don't need to fetch anything
    return null;
  };
}

// TODO: due to the current design of AccountSettings, we need to perform async data fetches for
// devices here. As such, for now, we need to limit when the fetch is done by location.
// Once we refactor AccountSettings, by actually eliminating the need for an AccountSettings
// module (and instead each Settings component becomes it's own route component), we can shift
// this fetching instruction over to the respective component (e.g. Devices).
function fetchMeDevicesAndProductIfNeeded({ location }) {
  return async (dispatch) => {
    if (location.pathname === ACCOUNT_DEVICES_PATH) {
      return dispatch(fetchMeDevices());
    }

    await dispatch(refreshMe());

    if (location.pathname === PAYMENT_PATH) {
      return dispatch(fetchProductIfNeeded(location));
    }
    return null;
  };
}

export default connectAsync([fetchMeDevicesAndProductIfNeeded])(
  AccountSettingsRoute,
);

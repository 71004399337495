import { disableLoadingState } from '../../constants/locationState';
import parse from '../../utils/queryString/parse';

export default function getSearchQueryObj(location) {
  const queryProp = location?.query?.query || '';
  const queryToken = location?.query?.itemtoken;
  const parsedSearch = parse(location?.search || ''); // lowercases the keys

  const { query, itemtoken: searchToken } = parsedSearch;

  const itemToken = queryToken || searchToken;
  const queryString = queryProp || query;

  const searchQueryObj = {
    query: queryString,
    [disableLoadingState]: location?.state?.disableLoadingState,
  };

  if (itemToken) {
    searchQueryObj.itemToken = itemToken;
  }

  return searchQueryObj;
}
